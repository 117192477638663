









































































import { Component, Vue, Prop } from 'vue-property-decorator'
import InlineEditable from './Components/InlineEditable.vue'
import Footer from './Components/Footer.vue'

import { IReportBlock, IReportBlockDataModel } from './IReportBlock'

import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import { ComparableTransaction, GetComparableSalesDetailsBlockModel } from 'truemarket-modules/src/models/api/reports/builder/blocks'
import { Modal } from '@/app_code/Modals/Modal'
import { DropdownField, FormField } from '@/app_code/Forms'
import Utils from 'truemarket-modules/src/Utils'

@Component({
  components: {
    InlineEditable,
    Footer
  }
})
export default class ComparableSalesDetailsBlock extends Vue implements IReportBlock {
  @Prop({ required: true })
  private readonly reportData!: GetValuationModel;

  @Prop({ required: true })
  private readonly block!: GetComparableSalesDetailsBlockModel;

  @Prop({ default: '1' })
  private readonly pageNumber!: string;

  async GetData (): Promise<IReportBlockDataModel> {
    return {} as IReportBlockDataModel
  }

  RemoveTransaction (id: string) {
    const idx = this.block.ComparableTransactions.findIndex(f => f.Id === id)

    if (idx !== -1) this.block.ComparableTransactions.splice(idx, 1)

    this.UpdateBlockData()
  }

  UpdateBlockData () {
    let datum = this.block.Data.find(d => d.Key === 'ComparableTransactions')

    if (!datum) {
      datum = { Key: 'ComparableTransactions', Value: '' }

      this.block.Data.push(datum)
    }

    datum.Value = JSON.stringify(this.block.ComparableTransactions)
  }

  AddProperty (): void {
    const modal = new Modal({
      Heading: 'Add Comparable Transaction',
      FormFields: [
        new FormField({ Type: 'text', Label: 'Property Address', Placeholder: 'E.g. 1 Example Lane, Portsmith QLD 4870', Key: 'Address' }),
        new FormField({ Type: 'text', Label: 'Type (House / Apartment / Etc.', Key: 'Type' }),
        new FormField({ Type: 'text', Label: 'No. of Bedrooms', Key: 'Beds' }),
        new FormField({ Type: 'text', Label: 'No. of Bathrooms', Key: 'Baths' }),
        new FormField({ Type: 'text', Label: 'No. of Car Spaces', Key: 'CarSpaces' }),
        new FormField({ Type: 'text', Label: 'Area (m2)', Key: 'Area' }),
        new FormField({ Type: 'textarea', Label: 'Description', Key: 'Description' }),
        new FormField({ Type: 'textarea', Label: 'Comments', Key: 'Comments' }),
        new DropdownField({ Label: 'Transaction Type', Key: 'TransactionType', Items: [{ Key: '0', Label: 'Sale' }, { Key: '1', Label: 'Lease' }] }),
        new FormField({ Type: 'text', Label: 'Transaction Amount ($)', Key: 'TransactionAmount' }),
        new FormField({ Type: 'url', Label: 'External Link', Key: 'ExternalLink' }),
        new FormField({ Type: 'text', Label: 'Transaction Date (dd/mm/yyyy)', Key: 'TransactionDate' })
      ],
      Buttons: [
        {
          Text: 'Cancel',
          Invert: true,
          OnClick: () => {
            modal.Hide()
          }
        },
        {
          Text: 'Save',
          Type: 'primary',
          OnClick: () => {
            modal.SetLoading(true)

            modal.BuildModel<ComparableTransaction>().then((txn) => {
              txn.Id = Utils.GetGuid()
              this.block.ComparableTransactions.push(txn)

              this.UpdateBlockData()

              modal.Hide()
            })
          }
        }
      ]
    })

    modal.Show()
  }
}
