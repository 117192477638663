



























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import InlineEditable from './Components/InlineEditable.vue'
import Footer from './Components/Footer.vue'

import { IReportBlock, IReportBlockDataModel } from './IReportBlock'
import CustomImage from './Components/CustomImage.vue'

import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import { AssessmentSummaryFacet, GetAssessmentSummaryBlockModel } from 'truemarket-modules/src/models/api/reports/builder/blocks'
import { FormField } from '@/app_code/Forms'
import { Modal } from '@/app_code/Modals/Modal'
import Utils from 'truemarket-modules/src/Utils'
import { Config } from 'truemarket-modules/src/environment'
import { Services } from 'truemarket-modules/src/services'
import { GetFileModel } from 'truemarket-modules/src/models/api/files'

@Component({
  components: {
    InlineEditable,
    Footer,
    CustomImage
  }
})
export default class AssessmentSummaryBlock extends Vue implements IReportBlock {
  @Prop({ required: true })
  private readonly reportData!: GetValuationModel;

  @Prop({ required: true })
  private readonly block!: GetAssessmentSummaryBlockModel;

  @Prop({ default: '1' })
  private readonly pageNumber!: string;

  private images: Record<string, GetFileModel> = {}

  apiBase = Config.API.Base

  async GetData (): Promise<IReportBlockDataModel> {
    return {} as IReportBlockDataModel
  }

  UpdateBlockData () {
    let datum = this.block.Data.find(d => d.Key === 'Facets')

    if (!datum) {
      datum = { Key: 'Facets', Value: '' }

      this.block.Data.push(datum)
    }

    datum.Value = JSON.stringify(this.block.Facets)

    this.UpdateImages()
  }

  @Watch('block.Facets')
  UpdateImages () {
    Services.API.Files.BulkGetFiles(this.block.Facets.filter(f => f.ImageId).map(f => f.ImageId).filter(e => e !== undefined) as string[]).then((imageMap) => {
      this.images = imageMap
    })
  }

  mounted () {
    this.UpdateImages()
  }

  RemoveSummary (id: string) {
    const idx = this.block.Facets.findIndex(f => f.Id === id)

    if (idx !== -1) this.block.Facets.splice(idx, 1)

    this.UpdateBlockData()
  }

  AddSummary (): void {
    const modal = new Modal({
      Heading: 'Add Summary Transaction',
      FormFields: [
        new FormField({ Type: 'text', Label: 'Heading', Placeholder: '', Key: 'Heading' }),
        new FormField({ Type: 'textarea', Label: 'Body', Key: 'Body' }),
        new FormField({ Type: 'userfile', Label: 'Image', Key: 'ImageId', Accept: ['image/jpg', 'image/png', 'image/jpeg'] })
      ],
      Buttons: [
        {
          Text: 'Cancel',
          Invert: true,
          OnClick: () => {
            modal.Hide()
          }
        },
        {
          Text: 'Save',
          Type: 'primary',
          OnClick: () => {
            modal.SetLoading(true)

            modal.BuildModel<AssessmentSummaryFacet>().then((facet) => {
              facet.Id = Utils.GetGuid()
              this.block.Facets.push(facet)

              console.log(facet)

              this.UpdateBlockData()

              modal.Hide()
            })
          }
        }
      ]
    })

    modal.Show()
  }
}
