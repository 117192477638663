




































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import InlineEditable from './Components/InlineEditable.vue'
import InlineCustomEnumValue from './Components/InlineCustomEnumValue.vue'
import PropertyImages from './Components/PropertyImages.vue'
import Footer from './Components/Footer.vue'

import { IReportBlock, IReportBlockDataModel } from './IReportBlock'
import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import LabelEnumValue from './Components/LabelEnumValue.vue'
import InlineEditableDate from './Components/InlineEditableDate.vue'

@Component({
  components: {
    InlineEditable,
    PropertyImages,
    Footer,
    InlineCustomEnumValue,
    LabelEnumValue,
    InlineEditableDate
  }
})
export default class PropertyAssessmentPageBlock extends Vue implements IReportBlock {
  @Prop({ required: true })
  private readonly reportData!: GetValuationModel;

  @Prop({ default: '1' })
  private readonly pageNumber!: string;

  async GetData (): Promise<IReportBlockDataModel> {
    return {} as IReportBlockDataModel
  }
}
