















import { Modal } from '@/app_code/Modals/Modal'
import { API, ServiceManager, ServiceType } from 'truemarket-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import EditOnly from '../../EditOnly.vue'

@Component({
  components: {
    EditOnly
  }
})
export default class CustomImage extends Vue {
  private mainImage = '/assets/reportbuilder/tmp/streetview.jpg'

  private mapImage = '/assets/reportbuilder/tmp/map.jpg'

  private cadastralImage = '/assets/reportbuilder/tmp/cadastral.jpg'

  api = ServiceManager.Require<API>(ServiceType.API)

  uploadPropertyImage (slot: string): void {
    const url = prompt('New URL')

    if (!url) return

    switch (slot) {
      case 'main':
        this.mainImage = url
        break
      case 'aux':
        this.mapImage = url
        break
      case 'cadastral':
        this.cadastralImage = url
        break
    }
  }
}
