






















































































































































































































































import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { IReportBlock, IReportBlockDataModel } from './IReportBlock'
import InlineCustomEnumValue from './Components/InlineCustomEnumValue.vue'
import { Services } from 'truemarket-modules/src/services'
import { DropdownField, DropdownListItem, FormField } from '@/app_code/Forms'
import { EnumValueType } from 'truemarket-modules/src/models/api'
import GetAttachedFileModel from 'truemarket-modules/src/models/api/files/GetAttachedFileModel'
import ValuationFileList from './ValuationFileList.vue'
import { Modal } from '@/app_code/Modals/Modal'
import GetPracticeModel from 'truemarket-modules/src/models/api/practices/GetPracticeModel'
import { Config } from 'truemarket-modules/src/environment'
import { ValuationStatus } from 'truemarket-modules/src/models/api/valuations/ValuationStatus'

@Component({
  components: {
    InlineCustomEnumValue,
    ValuationFileList
  }
})
export default class ValuationFormBlock extends Vue implements IReportBlock {
  @Prop({ required: true })
  private readonly valuation!: GetValuationModel;

  private files: GetAttachedFileModel[] = []

  private purpose: DropdownListItem[] = []
  private propertyType: DropdownListItem[] = []
  private propertyClassification: DropdownListItem[] = []

  private generalCondition: DropdownListItem[] = []
  private floorTypes: DropdownListItem[] = []
  private wallTypes: DropdownListItem[] = []
  private roofTypes: DropdownListItem[] = []

  private bedrooms: DropdownListItem[] = []
  private bathrooms: DropdownListItem[] = []
  private carParks: DropdownListItem[] = []
  private pool: DropdownListItem[] = []
  private outbuildings: DropdownListItem[] = []
  private dams: DropdownListItem[] = []
  private paddocks: DropdownListItem[] = []
  private bores: DropdownListItem[] = []

  private apiBase = Config.API.Base

  private hasErrored = false;

  private status = '20';

  @Watch('valuation.Property.PropertyType.ValueId')
  UpdatePropertySubType () {
    Services.API.Enumerations.GetEnumValueChildren(this.valuation.Property.PropertyType.ValueId, [EnumValueType.PropertySubType]).then((vals) => {
      this.propertyClassification = vals.map((v) => ({ Label: v.Label, Key: v.ValueId }))
    }).catch(() => {
      // alert(11111111111)
    })
  }

  mounted () {
    this.status = (this.valuation.Status).toString()
    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyType).then((vals) => {
      this.propertyType = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValueChildren(this.valuation.Property.PropertyType.ValueId, [EnumValueType.PropertySubType]).then((vals) => {
      this.propertyClassification = vals.map((v) => ({ Label: v.Label, Key: v.ValueId }))
    }).catch(() => {
      // alert(11111111111)
    })

    /*
    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertySubType).then((vals) => {
      this.propertyClassification = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })
    */

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.PropertyCondition).then((vals) => {
      this.generalCondition = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FloorConstructionType).then((vals) => {
      this.floorTypes = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.WallConstructionType).then((vals) => {
      this.wallTypes = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.RoofConstructionType).then((vals) => {
      this.roofTypes = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.ValuationPurpose).then((vals) => {
      this.purpose = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.ValuationPurpose).then((vals) => {
      this.purpose = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bedrooms).then((vals) => {
      this.bedrooms = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bathrooms).then((vals) => {
      this.bathrooms = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Parking).then((vals) => {
      this.carParks = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Pool).then((vals) => {
      this.pool = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Outbuildings).then((vals) => {
      this.outbuildings = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Dams).then((vals) => {
      this.dams = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.FencedPaddocks).then((vals) => {
      this.paddocks = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    Services.API.Enumerations.GetEnumValuesForType(EnumValueType.Bores).then((vals) => {
      this.bores = vals.map((v) => new DropdownListItem(v.ValueId, v.Label))
    })

    this.updateFiles()
  }

  updateFiles () {
    Services.API.Valuations.GetFiles(this.valuation.ValuationId).then((f) => {
      this.files = f
    })
  }

  async GetData (): Promise<IReportBlockDataModel> {
    return {} as IReportBlockDataModel
  }

  async Save (): Promise<boolean> {
    if (this.valuation.Contact == null) return true

    this.hasErrored = false

    try {
      await Services.API.CRM.SaveContact(this.valuation.Contact)
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    try {
      await Services.API.Valuations.SaveValuation({
        ValuationId: this.valuation.ValuationId,
        ContactId: this.valuation.Contact.ContactId,
        ReferrerUserId: null,
        ReferrerPracticeId: null,
        ValuerUserId: null,
        PropertySnapshotId: this.valuation.Property.PropertySnapshotId,
        Purpose: this.valuation.Purpose.ValueId,
        ReportDate: this.valuation.ReportDate.Value,
        RetrospectiveDate: this.valuation.RetrospectiveDate.Value,
        RetrospectiveDate2: this.valuation.RetrospectiveDate2,
        Heading: this.valuation.Heading,
        SubHeading: this.valuation.SubHeading,
        DisplayHeading: this.valuation.DisplayHeading,
        InvoiceId: null,
        Status: this.valuation.Status,
        CustomPurpose: this.valuation.CustomPurpose
      })
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    try {
      await Services.API.Property.SavePropertySnapshot({
        PropertyId: this.valuation.Property.PropertyId,
        PropertySnapshotId: this.valuation.Property.PropertySnapshotId,
        Address: this.valuation.Property.Address,
        PropertyType: this.valuation.Property.PropertyType.ValueId,
        PropertyClassification: this.valuation.Property.PropertyClassification.ValueId,
        FloorArea: this.valuation.Property.FloorArea,
        GeneralCondition: this.valuation.Property.GeneralCondition.ValueId,
        FloorConstruction: this.valuation.Property.FloorConstruction.ValueId,
        WallConstruction: this.valuation.Property.WallConstruction.ValueId,
        RoofConstruction: this.valuation.Property.RoofConstruction.ValueId,
        ApproximateBuildYear: this.valuation.Property.ApproximateBuildYear,
        LandArea: this.valuation.Property.LandArea,
        RPD: this.valuation.Property.RPD,
        LGA: this.valuation.Property.LGA,
        PropertyUse: this.valuation.Property.PropertyUse,
        Zoning: this.valuation.Property.Zoning,
        Comments: this.valuation.Property.Comments,
        Accommodation: this.valuation.Property.Accommodation,
        LastSale: this.valuation.Property.LastSale,
        LastSaleDate: this.valuation.Property.LastSaleDate.Value,
        PropertyAssessmentTypeComment: this.valuation.Property.PropertyAssessmentTypeComment,
        RentalAssessmentTypeComment: this.valuation.Property.RentalAssessmentTypeComment,
        ValuerInsights: this.valuation.Property.ValuerInsights,
        Improvements: this.valuation.Property.Improvements,
        LandPlanningTitle: this.valuation.Property.LandPlanningTitle,
        LocationNeighbourhood: this.valuation.Property.LocationNeighbourhood,
        MarketDirection: this.valuation.Property.MarketDirection,
        MarketActivity: this.valuation.Property.MarketActivity,
        MarketSegmentConditions: this.valuation.Property.MarketSegmentConditions
      })
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    try {
      if (this.valuation.CommercialDetails) await Services.API.Property.SaveCommercialDetails(this.valuation.CommercialDetails)
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    try {
      if (this.valuation.IndustrialDetails) await Services.API.Property.SaveIndustrialDetails(this.valuation.IndustrialDetails)
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    try {
      if (this.valuation.ResidentialDetails) await Services.API.Property.SaveResidentialDetails(this.valuation.ResidentialDetails)
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    try {
      if (this.valuation.RetailDetails) await Services.API.Property.SaveRetailDetails(this.valuation.RetailDetails)
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    try {
      if (this.valuation.RuralDetails) await Services.API.Property.SaveRuralDetails(this.valuation.RuralDetails)
    } catch (ex) { this.hasErrored = true; console.error(ex) }

    return true
  }

  async handleDoUpload (slot: string): Promise<void> {
    const mdl = new Modal({
      Heading: 'Upload File',
      FormFields: [
        new FormField({
          Type: 'userfile',
          OnGotValue: () => {
            mdl.GetFormValues().then((vals) => {
              if (!vals.Fields.length) return

              const fileId = vals.Fields[0].Value

              Services.API.Valuations.AttachFile(this.valuation.ValuationId, fileId, slot).then(() => {
                this.updateFiles();
                (this.$refs.fileList as ValuationFileList).updateFiles()

                mdl.Hide()
              })
            })
          }
        })
      ],
      Buttons: [
        {
          Type: 'info',
          Text: 'Cancel',
          OnClick: () => {
            mdl.Hide()
          }
        }
      ]
    })

    mdl.Show()
  }

  async assignPractice (): Promise<void> {
    const practices = await Services.API.Users.GetPractices()

    const modal = new Modal({
      Heading: 'Assign Practice',
      FormFields: [
        new DropdownField({
          Key: 'PracticeId',
          Label: 'Practice',
          Items: practices.map((p) => new DropdownListItem(p.PracticeId, p.Name))
        })
      ],
      Buttons: [
        {
          Text: 'Cancel',
          OnClick: () => {
            modal.Hide()
          }
        },
        {
          Text: 'Save',
          Type: 'primary',
          OnClick: () => {
            modal.GetFormValues().then((fd) => {
              const practiceIdField = fd.Fields.find((f) => f.Key === 'PracticeId')

              if (!practiceIdField) return

              Services.API.Valuations.SetValuationPractice(this.valuation.ValuationId, practiceIdField.Value).then(() => {
                this.$emit('data_updated')
                modal.Hide()
              })
            })
          }
        }
      ]
    })

    modal.Show()
  }

  async assignValuer (): Promise<void> {
    if (!this.valuation.Practice) return

    const valuers = await Services.API.Practices.GetValuersInPractice(this.valuation.Practice.PracticeId)

    const modal = new Modal({
      Heading: 'Assign Valuer',
      FormFields: [
        new DropdownField({
          Key: 'ValuerId',
          Label: 'Valuer',
          Items: valuers.map((p) => new DropdownListItem(p.UserId, p.FullName))
        })
      ],
      Buttons: [
        {
          Text: 'Cancel',
          OnClick: () => {
            modal.Hide()
          }
        },
        {
          Text: 'Save',
          Type: 'primary',
          OnClick: () => {
            modal.GetFormValues().then((fd) => {
              const valuerIdField = fd.Fields.find((f) => f.Key === 'ValuerId')

              if (!valuerIdField) return

              Services.API.Valuations.SetValuationValuer(this.valuation.ValuationId, valuerIdField.Value).then(() => {
                this.$emit('data_updated')
                modal.Hide()
              })
            })
          }
        }
      ]
    })

    modal.Show()
  }

  onStatusChange () {
    const modal = new Modal({
      Heading: 'Please Confirm',
      HtmlBody: 'Please confirm that you want to change the status.',
      Buttons: [
        {
          Text: 'Cancel',
          OnClick: () => {
            this.status = (this.valuation.Status).toString()
            modal.Hide()
          }
        },
        {
          Text: 'Confirm',
          Type: 'primary',
          OnClick: () => {
            modal.Hide()

            this.Save().then(() => {
              if (this.status === '11') {
                Services.API.Valuations.SetStatus(this.valuation.ValuationId, ValuationStatus.OnHold).then(() => {
                  // window.location.reload()
                })
              }

              if (this.status === '20') {
                Services.API.Valuations.SetStatus(this.valuation.ValuationId, ValuationStatus.InProgress).then(() => {
                  // window.location.reload()
                })
              }

              if (this.status === '22') {
                Services.API.Valuations.SetStatus(this.valuation.ValuationId, ValuationStatus.Incomplete).then(() => {
                  // window.location.reload()
                })
              }
            })
          }
        }
      ]
    })

    modal.Show()
  }

  completeValuation () {
    const modal = new Modal({
      Heading: 'Please Confirm',
      HtmlBody: 'Please confirm that you want to mark this valuation as complete.',
      Buttons: [
        {
          Text: 'Cancel',
          OnClick: () => {
            modal.Hide()
          }
        },
        {
          Text: 'Confirm',
          Type: 'primary',
          OnClick: () => {
            modal.Hide()

            this.Save().then(() => {
              Services.API.Valuations.SetStatus(this.valuation.ValuationId, ValuationStatus.Completed).then(() => {
                window.location.reload()
              })
            })
          }
        }
      ]
    })

    modal.Show()
  }
}
