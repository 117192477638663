










import { GetContactModel } from 'truemarket-modules/src/models/api/crm'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CustomerProfile extends Vue {
  @Prop({ required: true })
  private readonly contact!: GetContactModel;
}
