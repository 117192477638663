import CoverPageBlock from './CoverPageBlock.vue'
import PropertyAssessmentPageBlock from './PropertyAssessmentBlock.vue'
import AssessmentConsiderationsBlock from './AssessmentConsiderationsBlock.vue'
import ComparableSalesDetailsBlock from './ComparableSalesDetailsBlock.vue'
import AssessmentSummaryBlock from './AssessmentSummaryBlock.vue'
import ValuerInsightsBlock from './ValuerInsightsBlock.vue'
import DisclaimerBlock from './DisclaimerBlock.vue'
import { VueConstructor } from 'vue/types/umd'

const components: VueConstructor<Vue>[] = [
  CoverPageBlock,
  PropertyAssessmentPageBlock,
  AssessmentConsiderationsBlock,
  ComparableSalesDetailsBlock,
  AssessmentSummaryBlock,
  ValuerInsightsBlock,
  DisclaimerBlock
]

export default components
