








import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EditOnly extends Vue {
  private isEditing = true
}
