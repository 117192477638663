











import { Config } from 'truemarket-modules/src/environment'
import GetValuerProfileModel from 'truemarket-modules/src/models/api/valuations/GetValuerProfileModel'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ValuerProfile extends Vue {
  @Prop()
  private readonly valuer!: GetValuerProfileModel;

  private apiBase = Config.API.Base
}
