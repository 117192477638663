









































import { Component, Vue, Prop } from 'vue-property-decorator'
import InlineEditable from './Components/InlineEditable.vue'
import Footer from './Components/Footer.vue'

import { IReportBlock, IReportBlockDataModel } from './IReportBlock'
import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'

@Component({
  components: {
    InlineEditable,
    Footer
  }
})
export default class AssessmentConsiderationsBlock extends Vue implements IReportBlock {
  @Prop({ required: true })
  private readonly reportData!: GetValuationModel;

  @Prop({ default: '1' })
  private readonly pageNumber!: string;

  async GetData (): Promise<IReportBlockDataModel> {
    return {} as IReportBlockDataModel
  }
}
