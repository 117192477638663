

















import { Modal } from '@/app_code/Modals/Modal'
import { GetReportModel } from 'truemarket-modules/src/models/api/reports'
import { GetBlockBaseModel } from 'truemarket-modules/src/models/api/reports/builder/blocks'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ReportBuilderToolbar extends Vue {
  @Prop({ required: true })
  private readonly report!: GetReportModel;

  @Prop({ required: true })
  private readonly blocks!: GetBlockBaseModel[];

  isSaving = false

  async printReport () {
    window.open(`/admin/reports/print/${this.report.ReportId}`, '_blank')
    // this.$router.push(`/admin/reports/print/${this.report.ReportId}`)
  }

  async saveReport () {
    this.isSaving = true

    try {
      for (let i = 0; i < this.blocks.length; i++) {
        const block = this.blocks[i]

        await Services.API.Reports.SaveBlock(block.ReportId, block.BlockId, {
          Data: block.Data
        })
      }
    } catch (ex) {
      const modal = new Modal({
        Heading: 'Error',
        HtmlBody: `An error occurred while saving the report.<br/><br/>${JSON.stringify(ex)}`,
        Buttons: [
          {
            Text: 'Close',
            OnClick: () => {
              modal.Hide()
            }
          }
        ]
      })

      modal.Show()
    }

    this.isSaving = false
  }
}
