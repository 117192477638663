




































import { Modal } from '@/app_code/Modals/Modal'
import { Config } from 'truemarket-modules/src/environment'
import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import { API, ServiceManager, Services, ServiceType } from 'truemarket-modules/src/services'
import { Component, Prop, Vue } from 'vue-property-decorator'
import EditOnly from '../../EditOnly.vue'

@Component({
  components: {
    EditOnly
  }
})
export default class PropertyImages extends Vue {
  @Prop()
  private readonly valuation!: GetValuationModel;

  private mainImage = '/assets/reportbuilder/tmp/streetview.jpg'

  private mapImage = '/assets/reportbuilder/tmp/map.jpg'

  private cadastralImage = '/assets/reportbuilder/tmp/cadastral.jpg'

  api = ServiceManager.Require<API>(ServiceType.API)

  mounted () {
    if (this.valuation.Images.StreetView) this.mainImage = Config.API.Base + this.valuation.Images.StreetView.DownloadURL
    if (this.valuation.Images.LocationMap) this.mapImage = Config.API.Base + this.valuation.Images.LocationMap.DownloadURL
    if (this.valuation.Images.CadastralMap) this.cadastralImage = Config.API.Base + this.valuation.Images.CadastralMap.DownloadURL
  }

  uploadPropertyImage (slot: string): void {
    const fi = document.createElement('input')

    fi.type = 'file'
    fi.accept = 'image/jpg'

    fi.onchange = (e) => {
      if (fi.files) {
        Services.API.Files.Upload(fi.files[0]).then((file) => {
          switch (slot) {
            case 'main':
              this.mainImage = Config.API.Base + file.DownloadURL
              Services.API.Valuations.SetValuationImage(this.valuation.ValuationId, file.FileId, 0)
              break
            case 'aux':
              this.mapImage = Config.API.Base + file.DownloadURL
              Services.API.Valuations.SetValuationImage(this.valuation.ValuationId, file.FileId, 1)
              break
            case 'cadastral':
              this.cadastralImage = Config.API.Base + file.DownloadURL
              Services.API.Valuations.SetValuationImage(this.valuation.ValuationId, file.FileId, 2)
              break
          }
        })
      }
    }

    fi.style.display = 'none'

    document.body.append(fi)

    fi.click()
  }
}
