





import { DateModel } from 'truemarket-modules/src/models/api/common'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class InlineEditable extends Vue {
  @Prop({ default: '' })
  private readonly value!: string;

  @Prop({ default: 'text' })
  private readonly type!: 'text' | 'date';

  private val: string | object = ''

  @Watch('value')
  handleValUpdated (): void {
    if (this.value && this.value !== this.val) {
      if (this.type === 'date') {
        (this.$refs.elem as HTMLSpanElement).innerText = (this.value as unknown as DateModel).HtmlFormattedDate
      } else {
        (this.$refs.elem as HTMLSpanElement).innerText = this.value
      }

      this.val = this.value
    }
  }

  mounted () {
    if (this.value) {
      if (this.type === 'date') {
        (this.$refs.elem as HTMLSpanElement).innerText = (this.value as unknown as DateModel).HtmlFormattedDate
        this.val = this.value
      } else {
        (this.$refs.elem as HTMLSpanElement).innerText = this.value
        this.val = this.value
      }
    }
  }

  handleChange (override = false, overrideValue: string | object | null = null): void {
    let value: string | object

    if (overrideValue && override) {
      value = overrideValue
    } else {
      value = (this.$refs.elem as HTMLSpanElement).innerText.trim()
    }

    this.val = value
    this.$emit('input', value)
  }

  handlePaste (e: ClipboardEvent) {
    console.log(e)

    const paste = e.clipboardData?.getData('text')

    if (!paste) return

    const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
    const regex = new RegExp(expression)

    if (!regex.test(paste)) return

    e.preventDefault()

    const sel = this.getCaretCharacterOffsetWithin(this.$refs.elem as HTMLSpanElement) + 2

    console.log(sel, paste)

    const value = (this.$refs.elem as HTMLSpanElement).innerText

    const pre = value.substring(0, sel)

    const post = value.substring(sel)

    const span = (this.$refs.elem as HTMLSpanElement)

    span.innerHTML = `${pre.split('\n').join('<br/>')}<a href='${paste}' target='_blank' contenteditable="true">${paste}</a>${post.split('\n').join('<br/>')}`
  }

  getCaretCharacterOffsetWithin (element: HTMLElement) {
    let caretOffset = 0
    const doc = element.ownerDocument
    const win = doc.defaultView!

    let sel
    if (typeof win.getSelection !== 'undefined') {
      sel = win.getSelection()!

      if (sel.rangeCount > 0) {
        const range = win.getSelection()!.getRangeAt(0)
        const preCaretRange = range.cloneRange()
        preCaretRange.selectNodeContents(element)
        preCaretRange.setEnd(range.endContainer, range.endOffset)
        caretOffset = preCaretRange.toString().length
      }
    }

    return caretOffset
  }
}
