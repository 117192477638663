











import { EnumerationValueModel, EnumValueType } from 'truemarket-modules/src/models/api'
import { API, ServiceManager, ServiceType } from 'truemarket-modules/src/services'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class InlineCustomEnumValue extends Vue {
  @Prop({ required: true })
  private readonly type!: string;

  @Prop({ default: '' })
  private readonly value!: string;

  private vals: EnumerationValueModel[] = []

  private val = ''

  private label = ''

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted () {
    this.handleTypeUpdated().then(() => {
      this.val = this.value
    })
  }

  @Watch('value')
  handleValueUpdated (): void {
    this.val = this.value
  }

  @Watch('val')
  handleValUpdated (): void {
    this.label = this.vals.find((v) => v.ValueId === this.val)?.Label ?? 'N/A'
  }

  @Watch('type')
  handleTypeUpdated () {
    return new Promise((resolve, reject) => {
      this.api.Enumerations.GetEnumValuesForType(parseInt(this.type) as EnumValueType).then((vals) => {
        this.vals = vals
        resolve(true)
      })
    })
  }

  handleChange (): void {
    const value = (this.$refs.elem as HTMLSelectElement).value

    this.val = value

    this.$emit('input', value)
  }
}
