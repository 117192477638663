



























import ConsolePage from '@/components/Layout/ConsolePage.vue'
import { Config } from 'truemarket-modules/src/environment'
import { GetFileModel } from 'truemarket-modules/src/models/api/files'
import GetAttachedFileModel from 'truemarket-modules/src/models/api/files/GetAttachedFileModel'
import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

interface FileGroup {
  Key: string;
  Label: string;
  Files: GetFileModel[];
}

@Component
export default class ValuationFileList extends Vue {
  @Prop({ required: true })
  private readonly valuation!: GetValuationModel;

  private files: GetAttachedFileModel[] = []
  private fileGroups: FileGroup[] = []

  private typeMap: Record<string, string> = {
    TenancySchedule: 'Tenancy Schedule(s)',
    Photos: 'Photos',
    LeaseDocument: 'Lease Document(s)',
    BuildingPlan: 'Building Plan(s)'
  }

  private apiBase = Config.API.Base

  mounted () {
    this.updateFiles()
  }

  public updateFiles (): void {
    Services.API.Valuations.GetFiles(this.valuation.ValuationId).then((files) => {
      this.files = files
    })
  }

  async downloadFile (fileId: string): Promise<void> {
    await Services.API.Files.Download(fileId)
  }

  copyFileURL (fileURL: string) {
    navigator.clipboard.writeText('https://api.truemarket.com.au' + fileURL)
  }

  @Watch('files')
  handleFilesUpdated () {
    this.fileGroups = []

    const keys = Object.keys(this.typeMap)

    this.fileGroups = keys.map((key) => {
      return {
        Key: key,
        Label: this.typeMap[key],
        Files: this.files.filter((f) => f.AttachmentType === key).map((f) => f.File)
      } as FileGroup
    })
  }

  getIcon (file: GetFileModel): string {
    switch (file.Type) {
      case 'Image':
        return 'camera'
      case 'Document':
        return 'file-text'
      case 'Video':
        return 'video'
    }

    return 'download'
  }
}
