

















import { Component, Vue, Prop } from 'vue-property-decorator'
import InlineEditable from './Components/InlineEditable.vue'
import ValuerProfileByline from './Components/ValuerProfileByline.vue'
import Footer from './Components/Footer.vue'

import { IReportBlock, IReportBlockDataModel } from './IReportBlock'

import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import { GetBlockBaseModel } from 'truemarket-modules/src/models/api/reports/builder/blocks'
import { Services } from 'truemarket-modules/src/services'
import { GetReportModel } from 'truemarket-modules/src/models/api/reports'

@Component({
  components: {
    InlineEditable,
    Footer,
    ValuerProfileByline
  }
})
export default class ValuerInsightsBlock extends Vue implements IReportBlock {
  @Prop({ required: true })
  private readonly reportData!: GetValuationModel;

  @Prop()
  private readonly report!: GetReportModel;

  @Prop()
  private readonly block!: GetBlockBaseModel;

  @Prop({ default: false })
  private isLastBlock!: boolean;

  @Prop({ default: '1' })
  private readonly pageNumber!: string;

  async GetData (): Promise<IReportBlockDataModel> {
    return {} as IReportBlockDataModel
  }

  mounted () {
    Services.API.Reports.GetBlocks(this.report.ReportId).then((blocks) => {
      const result = blocks.filter(b => b.ComponentType === 'ValuerInsightsBlock')
      this.isLastBlock = result[result.length - 1].BlockId === this.block.BlockId
    })
  }
}
