












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Footer extends Vue {
  @Prop({ required: true })
  private readonly propertyAddress!: string;

  @Prop({ default: '1' })
  private readonly pageNumber!: string;
}
